import React from "react";
import './navBar.css';
import { AccountCircleOutlined, WhatsApp, Instagram } from "@mui/icons-material";
import UserMenu from "./userMenu/userMenu";
import { useNavigate } from "react-router-dom";

const NavBar = () => {

    const navigate = useNavigate();

    const handleProfesionales = () => {
        console.log("handleProfesionales")
    }

    const handleInstalaciones = () => {
        console.log("handleInstalaciones")
    }

    const handleServicios = () => {
        console.log("handleServicios")
    }

    const handleContacto = () => {
        console.log("handleContacto")
    }

    const handleLogo = () => {
        navigate("/login")
    }

    const handleInstagramLogo = () => {
        console.log("handleInstagramLogo")
        window.open("https://wa.me/3516794558", '_blank').focus();
    }

    const handleWhatsAppLogo = () => {
        window.open("https://wa.me/3516794558", '_blank').focus();
    }


    return (
        <div className="row w-100 navbar-container k-background-yellow">
            <div className="col-5 d-flex justify-content-end mt-4">
                {/* <span className="col-2 navBar-item" onClick={handleProfesionales}>Profesionales</span>
                <span className="col-2 navBar-item" onClick={handleInstalaciones}>Instalaciones</span> */}
            </div>
            <div className="col-2 d-flex justify-content-center navBar-item">
                <img src={require("../../assets/logo_no_background.png")} onClick={handleLogo} alt="logo" width={200} height={80} />
            </div>

            <div className="col-4 d-flex justify-content-start mt-4 row">
                {/* <span className="col-2 navBar-item" onClick={handleServicios}>Servicios</span>
                <span className="col-6 navBar-item" onClick={handleContacto}>Contacto</span> */}
            </div>
            <div className="col-1 d-flex justify-content-end mt-4 row">
                {/* <div className="col-4 navBar-item">
                    <img src={require("../../assets/instagram_logo.png")} onClick={handleInstagramLogo} alt="instagram_logo" width={24} height={24} />
                </div> */}
                <div className="col-4 navBar-item">
                    <WhatsApp className="whatsapp-color" onClick={handleWhatsAppLogo} />
                </div>
                <div className="col-4 navBar-item">
                    {/* <AccountCircleOutlined  onClick={ handleAccount}/> */}
                    <UserMenu />
                </div>
            </div>
        </div>
    );
}

export default NavBar;