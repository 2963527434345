import React from "react";
import { Box } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import ReactHookFormSelect from "../../../utils/reactHookForm/reactHookFormSelect";
import DatePicker from "../../../utils/reactHookForm/datePicker";

const PaymentForm = (props) => {

    const [startDate, setStartDate] = React.useState(dayjs(new Date));

    return (
        <>
            {/* first row */}
            <div className=" row w-100">
                {/* paymentStartDate */}
                <div className="col-6">
                    <Box my={2}>
                        <DatePicker 
                            name="paymentStartDate"
                            control={props.control}
                            defaultValue={startDate}
                            register={props.register}
                            label="Fecha de inicio"
                        />
                    </Box>
                </div>
                {/* paymentEndDate */}
                <div className="col-6">
                    <Box my={1}>
                        <ReactHookFormSelect
                            id="paymentPlan"
                            name="paymentPlan"
                            className="input-field"
                            label="Plan de pago"
                            control={props.control}
                            error={props.errors}
                            defaultValue={30}
                            variant="standard"
                        >
                            <MenuItem value="">
                                <em>Seleccionar plan</em>
                            </MenuItem>
                            <MenuItem value={30}>1 Mes (30 dias)</MenuItem>
                            <MenuItem value={90}>3 Meses (90 dias)</MenuItem>
                            <MenuItem value={180}>6 Meses (180 dias)</MenuItem>
                            <MenuItem value={365}>12 Meses (365 dias)</MenuItem>
                        </ReactHookFormSelect>
                    </Box>
                </div>
            </div>

        </>
    );
}

export default PaymentForm;