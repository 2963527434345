import AxionsInstance from "../axiosInstance";

export const __addUser = async (userData) => {
    const response = await AxionsInstance.post(`users/addUser`, userData);
    return response.data;
}

export const __updateProfile = async (userData) => {
    const response = await AxionsInstance.post(`users/updateProfile`, userData);
    return response.data;
}

export const updatePayment = async (paymentData) => {
    const response = await AxionsInstance.post(`users/addUseusers/updatePayment`, paymentData);
    return response.data;
}

export const __login = async (loginData) => {
    const response = await AxionsInstance.post(`users/login`, loginData);
    return response.data;
}

export const __getUserData = async (emailOrDni) => {
    const response = await AxionsInstance.post(`users/getUserData`, emailOrDni);
    return response.data;
}