import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import "./login.css";
import { __login } from '../../utils/services/usersService';
import { useDispatch } from 'react-redux';
import { SET_USER_DATA } from '../../redux/actions/userActions';
import { stringToBase64 } from '../../utils/encodeDecodeStrings';

const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        console.log("stringToBase64(data.password);: ", stringToBase64(data.password));
        data.password = stringToBase64(data.password);
        __login(data).then((res) => {
            dispatch(SET_USER_DATA(res));
            navigate(`/register`);
        })
            .catch((err) => {
                console.log("error at login: ", err);
            })
    };

    console.log(errors);

    return (
        <>
            <div className='d-grid justify-content-center mt-5 login'>
                <h3 className="d-flex justify-content-center k-text-pink mt-5">Ingresar</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box my={2}>
                        <TextField
                            name="email"
                            type="text"
                            label="Email"
                            placeholder="jhonny@email.com"
                            className="input-field"
                            variant="standard"
                            inputRef={register({
                                required: true,
                                pattern: /\S+@\S+\.\S+/
                            })}
                        />
                        <Box>
                            {errors.email && errors.email.type === "required" && (
                                <span className="error-message">Este campo es obligatorio</span>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <span className="error-message">Ingrese un email valido</span>
                            )}
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="password"
                            type="password"
                            label="Contraseña"
                            placeholder="Contraseña"
                            className="input-field"
                            variant="standard"
                            inputRef={register({
                                required: true
                            })}
                        />
                        <Box>
                            {errors.password && errors.password.type === "required" && (
                                <span className="error-message">Este campo es obligatorio</span>
                            )}
                        </Box>
                    </Box>
                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        className="k-background-blue mt-3"
                    >
                        Ingresar
                    </Button>
                </form>
                {/* <span className='mt-4'>Todavia no eres miembro? <strong className='k-text-blue' role="button" onClick={() => navigate(`/register`)}>Registrate Aqui!</strong></span> */}
            </div>
        </>
    )
}

export default Login;