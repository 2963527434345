import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";
import './mainLayout.css';
import { useNavigate } from "react-router-dom";
import { isLogged } from "../../utils/userManagement";
const MainLayout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogged()) {
            navigate("/login");
        }
    }, []);

    return (
        <div className="w-100 main-layout-container">
            <NavBar />
            <div className="main-layout-content">
                <Outlet />
            </div>
        </div>
    );
}

export default MainLayout;