import React from "react";
import { Box, TextField } from "@mui/material";

const UserProfilePaymentStatus = (props) => {

  return (
    <>
      {/* first row */}
      <div className=" row w-100">
        {/* paymenStatus */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="paymenStatus"
              type="text"
              label="Estado de pago"
              placeholder="Pagado"
              value={props.user?.paymenStatus === 0 ? "No pagado" : "Pagado"}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>

        {/* paymentStartDate */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="paymentStartDate"
              type="text"
              label="Fecha de inicio de pago"
              placeholder="25/10/1991"
              value={props.user?.paymentStartDate}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
        {/* paymentEndDate */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="paymentEndDate"
              type="text"
              label="Fecha de fin de pago"
              placeholder="25/11/1991"
              value={props.user?.paymentEndDate}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
      </div>

    </>
  );
}

export default UserProfilePaymentStatus;