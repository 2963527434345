import React from "react";

const Home = () => {
    return (
            <div className="d-grid justify-content-center mt-5">
                <h3 className="d-flex justify-content-start k-text-pink">Quienes somos</h3>
                <p className="w-75">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus urna dui, nec ullamcorper quam gravida id. Fusce sagittis dictum malesuada. Proin vestibulum tempor interdum. Nunc at est leo. Vivamus sem erat, consequat id sapien in, aliquet condimentum ligula. Maecenas ut justo sapien. Quisque semper nunc commodo, malesuada lectus sit amet, pulvinar neque. Donec ac consequat lacus, et ultricies neque. Maecenas sollicitudin, lacus vel pellentesque interdum, odio quam interdum massa, at commodo libero felis nec est. Nullam pellentesque tristique urna. Mauris non aliquet justo. Nunc augue neque, blandit sit amet tristique quis, porta in lectus.

                    Praesent placerat viverra nisl et imperdiet. Donec elementum maximus elit, ut porta ligula auctor at. Vivamus nec aliquet purus, vitae ultrices augue. Sed molestie et ante id volutpat. Donec nunc ante, auctor ut suscipit at, cursus a mauris. Nullam lacus dolor, sodales id arcu nec, sollicitudin mattis urna. Nam ante enim, mattis maximus iaculis vitae, feugiat nec felis. Vivamus sed rutrum nisi, nec rhoncus quam. Nulla vitae eros nec ex faucibus convallis. Quisque dignissim mauris nulla, placerat faucibus libero dapibus eget. Morbi consectetur nisl eu pulvinar mollis. Vivamus vel orci metus. Curabitur mauris orci, semper ac imperdiet eu, pharetra eu nisi.

                    Proin lobortis ultrices ex sit amet sodales. Cras leo justo, sagittis ac nunc nec, fringilla faucibus dolor. Aenean lobortis nisl nunc, ut viverra felis ullamcorper id. Sed suscipit sem a posuere viverra. Etiam libero quam, semper quis tortor sed, ultricies auctor nulla. Maecenas sollicitudin rutrum leo, sed luctus enim blandit sit amet. Praesent sed nulla ipsum. Vestibulum ornare risus nec enim blandit, vitae elementum est ullamcorper. Nulla tempor libero purus, nec ullamcorper elit consectetur eget. Suspendisse consectetur neque ac purus venenatis efficitur. Nullam sodales, risus vel sagittis dictum, dui eros posuere eros, ut euismod augue odio nec metus. Morbi vulputate, massa non ultrices lacinia, augue purus pulvinar quam, at volutpat elit felis ac ante. Praesent varius felis eget nisi molestie condimentum. Praesent rhoncus, metus at vestibulum tincidunt, urna ex rhoncus urna, ac varius lectus metus a justo. Duis sit amet rutrum dui, a ullamcorper mauris.

                    In hac habitasse platea dictumst. Aliquam nec ornare nisl. Mauris maximus elementum magna, ut sagittis dolor ultrices ut. Vestibulum aliquam, purus sed mattis pretium, arcu libero vehicula ante, nec volutpat nulla nisi vel libero. Quisque lobortis mi ante, at iaculis elit lobortis a. Nam facilisis tortor et augue luctus, vitae gravida dui pulvinar. Aliquam pellentesque ipsum id metus lobortis, id aliquet nibh semper. Maecenas a cursus diam. Sed eu iaculis ante. Morbi tempus, libero sit amet lobortis sagittis, nibh nulla pulvinar elit, ac pretium dui arcu ac turpis. Morbi non metus nibh. Proin fringilla sapien quis dapibus lobortis. Proin id leo in turpis dictum vestibulum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

                    Ut laoreet rutrum felis, vel faucibus elit varius in. Proin venenatis interdum diam id posuere. Aenean commodo pulvinar leo id lacinia. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sit amet blandit lorem, non sodales felis. Vestibulum eget elit nunc. Fusce felis libero, tincidunt quis vestibulum at, scelerisque ac arcu.

                    Vivamus eleifend posuere molestie. Nulla dictum euismod tempor. Praesent et odio nulla. Nullam vel fermentum diam. Aliquam ante lectus, feugiat in nulla at, iaculis euismod eros. Sed venenatis orci ut molestie porta. Pellentesque molestie libero quis maximus lacinia. Fusce nec nisi tincidunt, laoreet erat sit amet, accumsan metus. Nullam luctus tempor elit ac elementum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.

                    Fusce ut augue id libero efficitur dapibus eget vitae risus. Phasellus tincidunt iaculis consectetur. Suspendisse ut tortor at tortor malesuada aliquet et et elit. Vivamus at mauris magna. Aliquam sed magna dolor. Etiam ut aliquam urna. Nam ipsum tellus, sodales sit amet porta eu, consectetur quis nibh. Aliquam pharetra lorem quis diam aliquam feugiat. Pellentesque vitae metus scelerisque, tristique augue quis, facilisis dolor. Quisque tristique, justo eu pulvinar suscipit, odio elit dapibus mi, eget vehicula orci ipsum ut turpis. In mattis felis ut dignissim interdum. Nulla maximus ipsum sed velit tristique pellentesque. Vestibulum pretium orci mauris, ac condimentum lacus blandit non.

                    Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc nec gravida risus. Phasellus faucibus euismod nunc, sit amet gravida odio bibendum ut. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus eget faucibus leo, eu tincidunt libero. Ut lacus libero, ullamcorper sit amet neque ac, tempus egestas neque. Etiam ac turpis sit amet massa vulputate fringilla interdum vel enim. Etiam a convallis sapien, nec imperdiet libero. Donec velit dolor, condimentum quis libero eu, commodo varius felis. Integer vehicula, quam aliquam fermentum fringilla, odio massa pulvinar ligula, quis convallis dolor nunc ac ipsum. Sed fermentum dignissim libero, vitae placerat purus ornare sed. Praesent vel mi vitae libero feugiat consequat vitae at lacus. Nulla molestie velit non nulla cursus, quis placerat velit lobortis. Maecenas nibh massa, euismod in consequat vitae, sollicitudin sed ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel mi vitae diam egestas viverra.

                    Ut sollicitudin dapibus quam, vel condimentum ligula porta ut. Aliquam tempor malesuada ex sit amet elementum. Aliquam vitae diam ut metus tristique efficitur. Fusce sapien metus, lobortis at facilisis vel, sodales a eros. Duis posuere dolor eu quam rutrum, nec hendrerit nisl tristique. Sed ut ipsum lorem. Cras velit libero, vehicula eget massa in, congue tincidunt felis. Nam sit amet est laoreet nisi ullamcorper ultricies. Morbi velit enim, scelerisque viverra sapien ut, ultricies facilisis nunc. Ut odio diam, molestie at posuere in, ultrices et purus. Ut eget fringilla augue. Sed ultrices risus urna, nec accumsan orci accumsan at. Phasellus at nunc id libero aliquam efficitur. Cras ac commodo sem. Maecenas lobortis ex nec ante facilisis, quis placerat velit auctor.

                    Integer eget nisi eget urna ultricies scelerisque rutrum non urna. Etiam blandit nec elit et accumsan. Pellentesque iaculis, sapien dapibus elementum ullamcorper, tortor neque eleifend arcu, quis accumsan urna ex ac odio. Pellentesque massa nisl, feugiat et odio vulputate, efficitur tincidunt eros. Suspendisse potenti. Donec laoreet lacus ut elit interdum malesuada. Fusce eget mauris tincidunt, ullamcorper felis et, porta augue. Fusce quis ligula in orci porta commodo et eu sapien. Curabitur fermentum imperdiet augue. Etiam commodo nunc nec dolor convallis, ut vehicula turpis tincidunt. In ut interdum metus.
                </p>
            </div>
    );
}

export default Home;