import React from "react";
import { Box, TextField } from "@mui/material";

const UserProfilePersonalInfo = (props) => {
  return (
    <>
      {/* first row */}
      <div className=" row w-100">
        {/* ocupation */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="ocupation"
              type="text"
              label="Ocupación"
              placeholder="Administrativo"
              value={props.user?.ocupation}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.ocupation && props.errors.ocupation.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* sport_activity */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="sport_activity"
              type="text"
              label="Realiza actividad física"
              placeholder="Caminata/Correr"
              value={props.user?.sport_activity}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.sport_activity && props.errors.sport_activity.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
        {/* sport_activity_frequency */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="sport_activity_frequency"
              type="text"
              label="Frencuencia de actividad física"
              placeholder="1 vez por semana"
              value={props.user?.sport_activity_frequency}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.sport_activity_frequency && props.errors.sport_activity_frequency.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
        {/* inactive_time */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="inactive_time"
              type="text"
              label="Tiempo inactivo"
              placeholder="1 hora"
              value={props.user?.inactive_time}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.inactive_time && props.errors.inactive_time.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
      </div>

      {/* second row */}
      <div className=" row w-100">
        {/* sleep_avg */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="sleep_avg"
              type="text"
              label="Promedio de horas de sueño"
              placeholder="8 horas"
              value={props.user?.sleep_avg}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.sleep_avg && props.errors.sleep_avg.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
        {/* last_medical_check */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="last_medical_check"
              type="text"
              label="Ultima revisión médica"
              placeholder="1 año"
              value={props.user?.last_medical_check}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.last_medical_check && props.errors.last_medical_check.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* last_medical_check_status */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="last_medical_check_status"
              type="text"
              label="Estado de la ultima revisión médica"
              placeholder="Normal"
              value={props.user?.last_medical_check_status}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.last_medical_check_status && props.errors.last_medical_check_status.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* injury_surgery */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="injury_surgery"
              type="text"
              label="Lesiones o cirugías?"
              placeholder="Ninguna"
              value={props.user?.injury_surgery}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.injury_surgery && props.errors.injury_surgery.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
      </div>

      {/* third row */}
      <div className=" row w-100">
        {/* physical_pain */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="physical_pain"
              type="text"
              label="Dolor físico?"
              placeholder="SI/NO/CUAL"
              value={props.user?.physical_pain}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.physical_pain && props.errors.physical_pain.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
        {/* daily_routine */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="daily_routine"
              type="text"
              label="Rutina diaria"
              placeholder="Trabajo/Estudio"
              value={props.user?.daily_routine}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.daily_routine && props.errors.daily_routine.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* water_consumption */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="water_consumption"
              type="text"
              label="Consumo de agua diario"
              placeholder="2 litros"
              value={props.user?.water_consumption}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.water_consumption && props.errors.water_consumption.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* regular_medicine */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="regular_medicine"
              type="text"
              label="Medicamentos regulares"
              value={props.user?.regular_medicine}
              placeholder="Ninguno"
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.regular_medicine && props.errors.regular_medicine.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
      </div>

      {/* fourth row */}
      <div className=" row w-100">
        {/* allergies */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="allergies"
              type="text"
              label="Alergias"
              placeholder="Ninguna"
              value={props.user?.allergies}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.allergies && props.errors.allergies.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
        {/* covid */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="covid"
              type="text"
              label="Covid"
              placeholder="SI/NO"
              value={props.user?.covid}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.covid && props.errors.covid.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* objetive_results_expectation */}
        <div className="col-6">
          <Box my={2}>
            <TextField
              name="objetive_results_expectation"
              type="text"
              label="Objectivo / Expectativas / Resultados"
              placeholder="Quiero perder peso"
              value={props.user?.objetive_results_expectation}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.objetive_results_expectation && props.errors.objetive_results_expectation.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}

export default UserProfilePersonalInfo;