import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './pages/mainLayout/mainLayout';
import Login from './pages/login/login';
import Home from './pages/home/home';
import Register from './pages/register/register';
import UserProfile from './pages/userProfile/userProfile';
import { useSelector } from "react-redux";
import CheckEntrance from './pages/check-entrance/check-entrance';
import { isLogged, CheckRoleArray } from './utils/userManagement';
import NotFound from './pages/notFound/notFound';

const AppRoutes = () => {
    const user = useSelector((state) => state.userReducer.user);
    return (
        <Routes>
            <Route path="/" element={<MainLayout />} >
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />

                <Route path="*" element={<NotFound />} />

            </Route>
            {isLogged() && (
                <Route path="/" element={<MainLayout />} >
                    <Route path="/profile" element={<UserProfile />} />
                </Route>
            )}
            {CheckRoleArray(["administrativo", "super_admin"]) && (
                <Route path="/" element={<MainLayout />}>
                    <Route path="/check-entrance" element={<CheckEntrance />} />
                    <Route path="/register" element={<Register />} />
                </Route>
            )}
            {/* <Route path=":userId" element={< MainLayout />} >
                <Route path="/profile" element={<UserProfile />} />
            </Route> */}
        </Routes>
    )
}

export default AppRoutes;