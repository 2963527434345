import { store } from '../redux/store'
// import roles from './roles.json';
// import { useSelector } from "react-redux";

export function isLogged() {
  const user = store.getState().userReducer.user////useSelector(state => state.user);
  return user !== null;
}

export function userRole() {
  return store.getState().userReducer.user.role;//useSelector((state) => state.userReducer.user.role);
}


export function CheckRoleArray(roleArray) {
  const currentRole = store.getState().userReducer?.user?.role?.toLowerCase();
  const newRoleArray = [];
  roleArray.forEach((role, index) => {
    newRoleArray[index] = role;
  });
  return newRoleArray.includes(currentRole);
}
