import { USER_DATA, USER_LOGOUT } from "../actionTypes/userActionTypes";

const SET_USER_DATA = (payload) => ({
    type: USER_DATA,
    payload: payload
});

const SET_USER_LOGOUT = () => ({
    type: USER_LOGOUT
});

export { SET_USER_DATA, SET_USER_LOGOUT };

