import { USER_DATA, USER_LOGOUT } from "../actionTypes/userActionTypes";

const initialState = {
  user: {
    "id": 0,
    "roleId": null,
    "planId": null,
    "name": null,
    "lastName": null,
    "dateOfBirth": null,
    "dni": null,
    "email": null,
    "address": null,
    "phone": null,
    "password": null,
    "paymenStatus": null,
    "paymentStartDate": null,
    "paymentEndDate": null,
    "paymentPlan": null,
    "ocupation": null,
    "sport_activity": null,
    "sport_activity_frequency": null,
    "inactive_time": null,
    "sleep_avg": null,
    "last_medical_check": null,
    "last_medical_check_status": null,
    "injury_surgery": null,
    "physical_pain": null,
    "daily_routine": null,
    "water_consumption": null,
    "regular_medicine": null,
    "allergies": null,
    "covid": null,
    "objetive_results_expectation": null
  }
};

const userReducer = (state = initialState, action) => {
  console.log("action: ", action)
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case USER_LOGOUT:
      console.log('logout')
      localStorage.removeItem('persist:root');
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default userReducer;