import React from "react";
import "./user-menu.css";
import {
  ManageAccountsOutlined,
  Logout,
  AccountCircleOutlined,
  LoginOutlined,
  ScheduleOutlined,
  ArticleOutlined
} from "@mui/icons-material";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CheckRoleArray, isLogged, userRole } from "../../../utils/userManagement";
import { useDispatch } from 'react-redux';
import { SET_USER_LOGOUT } from '../../../redux/actions/userActions';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    setAnchorEl(null);
    navigate(`/login`);
  };

  const handleRegister = () => {
    setAnchorEl(null);
    navigate(`/register`);
  };

  const handleAccountSettings = () => {
    setAnchorEl(null);
    navigate(`/profile`);
  };

  const handleLogout = () => {
    console.log("logout click")
    handleClose();
    dispatch(SET_USER_LOGOUT());
    navigate('/login')
  };

  return (
    <div>
      <Button
        id="user-menu-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple={true}
      >
        <AccountCircleOutlined className="header-icon" style={{ color: "#616161" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!isLogged() && (
          <div>
            <MenuItem onClick={() => navigate(`/login`)} className="menu-item">
              <ListItemIcon className="menu-circle-question">
                <LoginOutlined />
              </ListItemIcon>
              <ListItemText>Ingresar</ListItemText>
            </MenuItem>
          </div>
        )}


        {isLogged() && (
          <div>
            {/* all can see profile */}
            <div>
              <MenuItem onClick={() => navigate(`/profile`)} className="menu-item">
                <ListItemIcon>
                  <ManageAccountsOutlined />
                </ListItemIcon>
                <ListItemText>Perfil</ListItemText>
              </MenuItem>
            </div>

            <div>
              {/* alumnos */}
              {CheckRoleArray(["alumno", "super_admin"]) && (
                <div>
                  <MenuItem onClick={() => navigate(`/`)} className="menu-item">
                    <ListItemIcon>
                      <ManageAccountsOutlined />
                    </ListItemIcon>
                    <ListItemText>Inscribirse</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => navigate(`/`)} className="menu-item">
                    <ListItemIcon>
                      <ScheduleOutlined />
                    </ListItemIcon>
                    <ListItemText>Horarios</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => navigate(`/`)} className="menu-item">
                    <ListItemIcon>
                      <ArticleOutlined />
                    </ListItemIcon>
                    <ListItemText>Mis Planes</ListItemText>
                  </MenuItem>
                </div>
              )}

              {/* profesores */}
              {CheckRoleArray(["profesor", "super_admin"]) && (
                <div>
                  <MenuItem onClick={() => navigate(`/`)} className="menu-item">
                    <ListItemIcon>
                      <ArticleOutlined />
                    </ListItemIcon>
                    <ListItemText>Cargar nuevo plan</ListItemText>
                  </MenuItem>
                </div>
              )}

              {/* super_admin */}
              {CheckRoleArray(["super_admin"]) && (
                <div>
                  <MenuItem onClick={() => navigate(`/`)} className="menu-item">
                    <ListItemIcon>
                      <ArticleOutlined />
                    </ListItemIcon>
                    <ListItemText>Promover usuarios</ListItemText>
                  </MenuItem>
                </div>
              )}

              {/* administrativo */}
              {CheckRoleArray(["administrativo", "super_admin"]) && (
                <div>
                  <MenuItem onClick={() => navigate(`/register`)} className="menu-item">
                    <ListItemIcon>
                      {/* <AutoDeleteOutlined /> */}
                    </ListItemIcon>
                    <ListItemText>Registrar Usuario</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => navigate(`/check-entrance`)} className="menu-item">
                    <ListItemIcon>
                      <ArticleOutlined />
                    </ListItemIcon>
                    <ListItemText>Controlar Ingresos</ListItemText>
                  </MenuItem>
                </div>
              )}

              <Divider className="menu-divider" />

              <MenuItem onClick={() => handleLogout()} className="menu-item">
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>Cerrar Sesion</ListItemText>
              </MenuItem>
            </div>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
