import React from "react";

const NotFound = () => {
    return (
        <div className="d-flex justify-content-center">
        <img src={require("../../assets/404.jpg")} alt="404" width={window.innerWidth - 20} height={window.innerHeight - 100} />
        </div>
    );
    }

export default NotFound;