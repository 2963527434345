import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import "./userProfile.css";
import { __updateProfile } from '../../utils/services/usersService';
import UserProfileInfo from "./sections/userProfileInfo";
import UserProfilePersonalInfo from "./sections/userProfilePersonalInfo";
import UserProfilePaymentStatus from "./sections/userProfilePaymentStatus";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, errors } = useForm();
  const user = useSelector((state) => state.userReducer.user);

  const onSubmit = (data) => {
    // data.id = 1;
    if (data.password !== data.passwordConfirmation) { alert("Las contraseñas no coinciden"); return }
    __updateProfile(data).then((res) => {
      navigate(`/home`);
    })
      .catch((err) => {
        console.log(err);
      })
  };

  console.log(errors);

  return (
    <>
      <div className='d-grid justify-content-center mt-5 user-profile'>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="d-flex justify-content-center k-text-pink mt-5">Mi Perfil</h3>
          <UserProfileInfo register={register} errors={errors} user={user} />

          <hr className="k-text-blue user-profile-separator" />
          <h3 className="d-flex justify-content-center k-text-pink">Informacion Personal</h3>
          <UserProfilePersonalInfo register={register} errors={errors} user={user} />

          <hr className="k-text-blue user-profile-separator" />
          <h3 className="d-flex justify-content-center k-text-pink">Habilitado</h3>
          <UserProfilePaymentStatus register={register} errors={errors} user={user} />

          <Button
            color="primary"
            type="submit"
            variant="contained"
            className="k-background-blue mt-3"
          >
            Guardar
          </Button>
        </form>
      </div>
    </>
  );
}

export default UserProfile;