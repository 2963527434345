import axios from 'axios';

const token = "hghdjwodhbgou23h3rew894y42596rhsgn"
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${token}`;
    // OR config.headers.common['Authorization'] = `Bearer ${your_token}`;
    config.baseURL = process.env.REACT_APP_BASE_URL;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  console.log("axios error response interceptor: ", error.response.data)
  if(error.response.data.message) alert(error.response.data.message)
  if (error.response.status === 401) console.log("Unauthorized")
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};


// usagge example
    //get:
    //     AxionsInstance.get("todos/1", {
    //             title: "Hello World!"
    //         })
    //         .then((response) => {
    //            console.log(response)
    //         });

    //post:
    //         AxionsInstance.post("posts/1", {
    //             body: {
    //                 "userId": 1,
    //                 "id": 1,
    //                 "title": "sunt aut facere repellat provident occaecati excepturi optio reprehenderit",
    //                 "body": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
    //               }
    //             })
    //             .then((response) => {
    //                console.log(response)
    //             });