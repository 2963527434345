import moment from "moment/moment";

export const formatDateToddMMyyyy = (date) => {
  let isoDate = date.toISOString().split("T")[0].replace(/-/g, "/").split('/')
  return isoDate[2] + "/" + isoDate[1] + "/" + isoDate[0];
}

export const addDaysToDate = (date, days) => {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return formatDateToddMMyyyy(newDate);
}

export const validateDatesBeetween = (startDate, endDate) => {
  let startDateArray = startDate.split("/");
  let endDateArray = endDate.split("/");
  if (startDateArray[2] === endDateArray[2]) {
    if (startDateArray[1] < endDateArray[1]) {
      if (startDateArray[0] < endDateArray[0]) {
        return true;
      }
    } else { return false }
  } else {
    if (startDateArray[2] < endDateArray[2]) { return true }
    else { return false }
  }
}