import React from 'react';
import { TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useController } from "react-hook-form";


const DatePicker = ({
    name,
    control,
    defaultValue,
    ...props
}) => {
    const { field } = useController({
        name,
        control,
        defaultValue,
    });
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DesktopDatePicker
                {...field}
                label={props.label}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) =>
                <TextField
                    name={props.name}
                    variant="standard"
                    inputRef={props.register({
                        required: true
                    })} {...params} />}
            />
        </LocalizationProvider>
    );
};


export default DatePicker;