import React, { useEffect, useState } from "react";
import { __getUserData } from "../../utils/services/usersService";
import ShowUserData from "./showUserData.jsx";
import { Box, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CheckEntrance = () => {

    const navigate = useNavigate();

    const [emailOrDni, setEmailOrDni] = useState("");
    const [userData, setUserData] = useState(null);

    const handleSearch = () => {
        __getUserData({ emailOrDni: emailOrDni }).then((res) => {
            console.log("res: ", res);
            setUserData(res);
        })
            .catch((err) => {
                console.log("err: ", err);
            })
    }

    // useEffect(() => {
    //     __getUserData({emailOrDni: 'a121111111a@aa.com'}).then((res) => {
    //         console.log("res: ", res);
    //     })
    //     .catch((err) => {
    //         console.log("err: ", err);
    //     })
    // }, []);

    return (
        <div className="d-grid justify-content-center mt-5">
            <h1 className="k-text-pink">Validar Usuario</h1>
            <div className="row mt-5">
                <TextField
                    name="emailOrDni"
                    type="text"
                    label="Email o DNI"
                    placeholder="20473837"
                    value={emailOrDni}
                    onChange={(e) => setEmailOrDni(e.target.value)}
                    className="input-field col-5"
                    variant="standard"
                />
                <div className="col-1"></div>
                <Button
                    color="primary"
                    variant="contained"
                    className="k-background-blue col-2"
                    onClick={handleSearch}
                >
                    Buscar
                </Button>
                <div className="col-1"></div>
                <Button
                    color="primary"
                    variant="contained"
                    className="k-background-blue col-3"
                    onClick={ () => navigate(`/register`) }
                >
                    Registrar
                </Button>
            </div>
            <div className="mt-5">
                {userData && <ShowUserData user={userData} />}
            </div>
        </div>
    );
}


export default CheckEntrance;