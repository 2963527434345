import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import "./register.css";
import { __addUser } from '../../utils/services/usersService';
import PaymentForm from "./paymentForm/paymentForm";
import { formatDateToddMMyyyy, addDaysToDate } from "../../utils/dateFucntions";
import { stringToBase64 } from "../../utils/encodeDecodeStrings";
import { Navigate } from "react-router-dom";
import { useStore } from "react-redux";


const Register = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, reset, errors, control } = useForm();
  const currentUserEmail = useStore().getState().userReducer.user.email;
  console.log("currentuser:", currentUserEmail);

  const onSubmit = (data) => {
    if(!validDateOfBirth(data.dateOfBirth)) return;
    data.paymentEndDate = addDaysToDate(data.paymentStartDate.$d, data.paymentPlan);
    data.paymentStartDate = formatDateToddMMyyyy(data.paymentStartDate.$d);//.toISOString().split("T")[0].replace(/-/g, "/");
    data.password = stringToBase64(data.dni);
    data.registeredBy = currentUserEmail;
    console.log("data: ", data)
    if (data.email !== data.emailConfirmation) { alert("Los emails no coinciden"); return }
    // if(data.password !== data.passwordConfirmation) {alert("Las contraseñas no coinciden"); return }
    __addUser(data).then((res) => {
      console.log(res);
      // navigate(`/login`);
      alert(res.message)
      reset()
    })
      .catch((err) => {
        console.log(err);
      })
  };

  console.log(errors);

  const validDateOfBirth = (date) => {
    let validDateOfBirth = date.split("/");
    if(validDateOfBirth.length > 0){
      if(validDateOfBirth[0] > 31) {
        alert("Ingrese un dia valido para la fecha de nacimiento");
        return false;
      }
      if(validDateOfBirth[1] > 12) {
        alert("Ingrese un mes valido para la fecha de nacimiento");
        return false;
      }
      if(validDateOfBirth[2].length !== 4 || parseInt(validDateOfBirth[2]) < 1900) {
        alert("Ingrese un año valido para la fecha de nacimiento (4 digitos)");
        return false;
    }
    else return true;

    }
    else {
      alert("Fecha de nacimiento invalida, ingrese una fecha valida, ejemplo: 09/08/1990");
      return false;
    }
  }



  return (
    <>
      <div className='d-grid justify-content-center mt-5 register'>
        <h3 className="d-flex justify-content-center k-text-pink mt-5">Formulario de registro</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* first row */}
          <div className="row">
            {/* name */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="name"
                  type="text"
                  label="Nombre"
                  placeholder="Jhonny"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true
                  })}
                />
                <Box>
                  {errors.name && errors.name.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                </Box>
              </Box>
            </div>

            {/* lastName */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="lastName"
                  type="text"
                  label="Apellido"
                  placeholder="Gonzales"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true
                  })}
                />
                <Box>
                  {errors.lastName && errors.lastName.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                </Box>
              </Box>
            </div>
          </div>

          {/* second row */}
          <div className="row">
            {/* dateOfBirth */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="dateOfBirth"
                  type="text"
                  label="Fecha de nacimiento"
                  placeholder="25/10/1991"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true
                  })}
                />
                <Box>
                  {errors.dateOfBirth && errors.dateOfBirth.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                </Box>
              </Box>
            </div>

            {/* dni */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="dni"
                  type="number"
                  label="D.N.I"
                  placeholder="27745123"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true,
                    minLength: 8
                  })}
                />
                <Box>
                  {errors.dni && errors.dni.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                  {errors.dni && errors.dni.type !== "minLength" && (
                    <span className="error-message">
                      Numero de caracteres invalidos
                    </span>
                  )}
                </Box>
              </Box>
            </div>
          </div>

          {/* third row */}
          <div className="row">
            {/* email */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="email"
                  type="text"
                  label="Email"
                  placeholder="jhonny@email.com"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true,
                    pattern: /\S+@\S+\.\S+/
                  })}
                />
                <Box>
                  {errors.email && errors.email.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span className="error-message">Ingrese un email valido</span>
                  )}
                </Box>
              </Box>
            </div>

            {/* emailConfirmation */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="emailConfirmation"
                  type="text"
                  label="Confirmar email"
                  placeholder="jhonny@email.com"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true,
                    pattern: /\S+@\S+\.\S+/
                  })}
                />
                <Box>
                  {errors.emailConfirmation && errors.emailConfirmation.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                  {errors.emailConfirmation && errors.emailConfirmation.type === "pattern" && (
                    <span className="error-message">Ingrese un email valido</span>
                  )}
                </Box>
              </Box>
            </div>
          </div>

          {/* fourth row */}
          <div className="row">
            {/* address */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="address"
                  type="text"
                  label="Direccion"
                  placeholder="Av. Siempreviva 123"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true
                  })}
                />
                <Box>
                  {errors.address && errors.address.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                </Box>
              </Box>
            </div>

            {/* phone */}
            <div className="col-6">
              <Box my={2}>
                <TextField
                  name="phone"
                  type="text"
                  label="Telefono"
                  placeholder="0351-155432314"
                  className="input-field"
                  variant="standard"
                  inputRef={register({
                    required: true
                  })}
                />
                <Box>
                  {errors.phone && errors.phone.type === "required" && (
                    <span className="error-message">Este campo es obligatorio</span>
                  )}
                </Box>
              </Box>
            </div>
          </div>

          {/* fifth row */}
          <div className="row">
            <div className="col-6">
              {/* <Box my={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Fecha de inicio"
                    inputFormat="DD/MM/YYYY"
                    // value={startDate}
                    // onChange={handleChangeStartDate}
                    // name="paymentStartDate"
                    //     variant="standard"
                    //     className="input-field"
                    //     inputRef={register({
                    //       required: true
                    //     })}
                    renderInput={(params) =>
                      <TextField
                        name="paymentStartDate"
                        type="text"
                        label="Telefono"
                        placeholder="0351-155432314"
                        className="input-field"
                        variant="standard"
                        inputRef={register({
                          required: true
                        })}
                        {...params} />}
                  />
                </LocalizationProvider>
              </Box> */}
            </div>
            {/* paymentEndDate */}
            <div className="col-6">
              <Box my={1}>
                {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">Plan de pago</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    // value={paymentPlan}
                    // onChange={handleChangePaymentPlan}
                    className="input-field"
                    label="Plan de pago"
                    inputRef={register({
                      required: true
                    })}
                    name="paymentPlan"
                  >
                    <MenuItem value="">
                      <em>Seleccionar plan</em>
                    </MenuItem>
                    <MenuItem value={30}>1 Mes (30 dias)</MenuItem>
                    <MenuItem value={90}>3 Meses (90 dias)</MenuItem>
                    <MenuItem value={180}>6 Meses (180 dias)</MenuItem>
                    <MenuItem value={365}>12 Meses (365 dias)</MenuItem>
                  </Select>
                </FormControl> */}

              </Box>
            </div>
            <div className="col-6">
              {/* password */}
              {/* <Box mb={2}>
                    <TextField
                        name="password"
                        type="password"
                        label="Contraseña"
                        placeholder="Contraseña"
                        className="input-field"
                        variant="standard"
                        inputRef={register({
                        required: true,
                        })}
                    />
                      <Box>
                          {errors.password && errors.password.type === "required" && (
                          <span className="error-message">Este campo es obligatorio</span>
                          )}
                      </Box>
                  </Box> */}
            </div>

            {/* passwordConfirmation */}
            <div className="col-6">
              {/* <Box mb={2}>
                    <TextField
                        name="passwordConfirmation"
                        type="password"
                        label="Confirmar contraseña"
                        placeholder="Confirmar contraseña"
                        className="input-field"
                        variant="standard"
                        inputRef={register({
                        required: true,
                        minLength: 8
                        })}
                    />
                      <Box>
                          {errors.passwordConfirmation && errors.passwordConfirmation.type === "required" && (
                          <span className="error-message">Este campo es obligatorio</span>
                          )}
                          {errors.passwordConfirmation && errors.passwordConfirmation.type === "minLength" && (
                          <span className="error-message">
                              Minimo 8 caracteres
                          </span>
                          )}
                      </Box>
                  </Box> */}
            </div>
          </div>

          <PaymentForm register={register} errors={errors} control={control}></PaymentForm>

          <div className="row mt-3">
            <Button
              color="primary"
              type="submit"
              variant="contained"
              className="k-background-blue col-4"
            >
              Registrar
            </Button>
                  <div className="col-2"></div>
            <Button
              color="primary"
              variant="contained"
              className="k-background-blue col-6"
              onClick={() => navigate(`/check-entrance`)}
            >
              Validar Usuario
            </Button>
          </div>
        </form>
        {/* <span className='mt-4 justify-content-center d-flex'>Ya eres miembro? &nbsp;<strong className='k-text-blue' role="button" onClick={() => navigate(`/login`)}>Ingresar</strong></span> */}
      </div>
    </>
  );
}

export default Register;