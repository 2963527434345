import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { validateDatesBeetween, formatDateToddMMyyyy } from "../../utils/dateFucntions";
import './check-entrance.css';

const ShowUserData = (props) => {
  console.log("props: ", props);

  return (
    <>
      {/* first row */}
      <div className=" row w-100">
        {/* name */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="name"
              type="text"
              label="Nombre"
              placeholder="Jhonny"
              value={props.user?.name}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>

        {/* lastName */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="lastName"
              type="text"
              label="Apellido"
              placeholder="Gonzales"
              value={props.user?.lastName}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
        {/* dateOfBirth */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="dateOfBirth"
              type="text"
              label="Fecha de nacimiento"
              placeholder="25/10/1991"
              value={props.user?.dateOfBirth}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
        {/* dni */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="dni"
              type="text"
              label="D.N.I"
              placeholder="27.745.123"
              value={props.user?.dni}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
      </div>

      {/* second row */}
      <div className=" row w-100">
        {/* email */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="email"
              type="text"
              label="Email"
              placeholder="jhonny@email.com"
              value={props.user?.email}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
        {/* address */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="address"
              type="text"
              label="Direccion"
              placeholder="Av. Siempreviva 123"
              value={props.user?.address}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>

        {/* phone */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="phone"
              type="text"
              label="Telefono"
              placeholder="0351-155432314"
              value={props.user?.phone}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>

      </div>
      <div className=" row w-100">
        {/* paymenStatus */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="paymenStatus"
              type="text"
              label="Estado de pago"
              placeholder="Pagado"
              value={validateDatesBeetween(formatDateToddMMyyyy(new Date), props.user.paymentDetails?.paymentEndDate) ? "Pagado" : "No Pagado"}
              className={`input-field ${validateDatesBeetween(formatDateToddMMyyyy(new Date), props.user.paymentDetails?.paymentEndDate) ? "payed" : "not-payed"}`}
              variant="standard"
            />
          </Box>
        </div>

        {/* paymentStartDate */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="paymentStartDate"
              type="text"
              label="Fecha de inicio de pago"
              // placeholder="25/10/1991"
              value={props.user?.paymentDetails?.paymentStartDate}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
        {/* paymentEndDate */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="paymentEndDate"
              type="text"
              label="Fecha de fin de pago"
              // placeholder="25/11/1991"
              value={props.user?.paymentDetails?.paymentEndDate}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
      </div>
    </>
  );
}

export default ShowUserData;