import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const UserProfileInfo = (props) => {

  return (
    <>
      {/* first row */}
      <div className=" row w-100">
        {/* name */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="name"
              type="text"
              label="Nombre"
              placeholder="Jhonny"
              value={props.user?.name}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.name && props.errors.name.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* lastName */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="lastName"
              type="text"
              label="Apellido"
              placeholder="Gonzales"
              value={props.user?.lastName}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.lastName && props.errors.lastName.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
        {/* dateOfBirth */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="dateOfBirth"
              type="text"
              label="Fecha de nacimiento"
              placeholder="25/10/1991"
              value={props.user?.dateOfBirth}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.dateOfBirth && props.errors.dateOfBirth.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
        {/* dni */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="dni"
              type="text"
              label="D.N.I"
              placeholder="27.745.123"
              value={props.user?.dni}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.dni && props.errors.dni.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>
      </div>

      {/* second row */}
      <div className=" row w-100">
        {/* email */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              disabled
              name="email"
              type="text"
              label="Email"
              placeholder="jhonny@email.com"
              value={props.user?.email}
              className="input-field"
              variant="standard"
            />
          </Box>
        </div>
        {/* address */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="address"
              type="text"
              label="Direccion"
              placeholder="Av. Siempreviva 123"
              value={props.user?.address}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.address && props.errors.address.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* phone */}
        <div className="col-3">
          <Box my={2}>
            <TextField
              name="phone"
              type="text"
              label="Telefono"
              placeholder="0351-155432314"
              value={props.user?.phone}
              className="input-field"
              variant="standard"
              inputRef={props.register({
                required: true
              })}
            />
            <Box>
              {props.errors.phone && props.errors.phone.type === "required" && (
                <span className="error-message">Este campo es obligatorio</span>
              )}
            </Box>
          </Box>
        </div>

        {/* password */}
        <div className="col-3">
          <Button
            color="primary"
            variant="contained"
            className="k-background-blue mt-3"
          >
            Cambiar contraseña
          </Button>
        </div>
      </div>
    </>
  );
}

export default UserProfileInfo;